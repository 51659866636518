import { useEffect, useState, useMemo } from "react";
import { useRouter } from "next/router";

import { NavigationHeader } from "./NavigationHeader";
import { MobileMenu } from "./MobileMenu";

interface Navigation {
  darkMode: boolean;
  setDarkMode: (b: boolean) => void;
}

const HIDDEN_HEADER_PATHS = ["/asset/[contract]/[tokenId]/render"];
const TRANSPARENT_HEADER_PATHS = ["/"];
const TRANSPARENT_SCROLL_Y_LIMIT = 60;
const CONSTRAIN_WIDTH_HEADER_PATHS = ["/"];

export const Navigation = ({ darkMode, setDarkMode }: Navigation) => {
  const router = useRouter();

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const isTransparentHeaderPath = TRANSPARENT_HEADER_PATHS.includes(
    router.pathname
  );
  const isConstrainWidthPath = CONSTRAIN_WIDTH_HEADER_PATHS.includes(
    router.pathname
  );
  const isHideHeaderPath = HIDDEN_HEADER_PATHS.includes(router.pathname);

  // close mobile menu
  useEffect(() => {
    if (showMobileMenu) setShowMobileMenu(false);
  }, [router.pathname]);

  // dertermine if page is scolled to enable/disable header transparency
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition >= TRANSPARENT_SCROLL_Y_LIMIT);
    };

    if (isTransparentHeaderPath) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isTransparentHeaderPath]);

  // Header transparency goes away as you scroll
  const isTransparent = useMemo(
    () => isTransparentHeaderPath && !isScrolled,
    [isTransparentHeaderPath, isScrolled]
  );

  if (isHideHeaderPath) return null;

  return (
    <div className="top-0 z-40 sticky">
      <NavigationHeader
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        showMobileMenu={() => setShowMobileMenu(true)}
        constrainMaxWidth={isConstrainWidthPath}
        transparent={isTransparent}
        border={!isTransparentHeaderPath} // turn of border if we use transparency
      />
      <MobileMenu
        show={showMobileMenu}
        closeMenu={() => setShowMobileMenu(false)}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
    </div>
  );
};
